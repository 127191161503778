.CreateAvt{
    display: flex;
    flex-direction: column;
}

.CreateAvt > *{
    margin-top: 5px;
}

.CreateAvt textarea{
    margin-top: 5px;
    margin-bottom: 16px;
    height: 150px;
}

.CreateAvt label{
    font-size: 25px;
    font-weight: bold;
}
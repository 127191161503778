header{
    display: flex;
    align-items: center;
    background-color: #525192;
    height: 86px;
}

header > div{
    display: flex;
    padding: 16px 0px;
    align-items: center;
}

header > :first-child{
    width: 100px;
}

header > div{
    margin-left: 16px;
}

header .Logo{
    width: 100%;
}

header > .Menu{
    width: 100%;
    min-height: 100%;
}

header > .Menu > .Item{
    font-family: var(--font-family);
    margin-left: 16px;
    display: flex;
    align-items: center;
    font-size: 22px;
}

header > .Menu > .Empty{
    width: 100%;
}

header > .Menu > .Logout{
    justify-content: flex-end;
    display: flex;
    margin-right: 16px;
}

header a{
    color: white;
    font-weight: bold;
    font-size: larger;
}

header a:hover{
    color: rgb(176, 173, 194);
}


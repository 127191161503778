.TotalField{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
    margin-top: 8px;
}

.TotalField > div{
    min-width: 145px;
}

.TotalField > :nth-child(2){
    min-width: 145px;
    text-align: end;
}

.TotalField.Total{
    color: black;
}
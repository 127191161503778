.Articles{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Article{
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    display: flex;
    height: 100px;
    width: 100%;
    border: .5px black solid;
    border-bottom: none;
    border-collapse: collapse;
    border-radius: 2px;
    padding: 16px;
    cursor: pointer;
    background-color: rgb(252, 241, 241);
}

.Article > img {
    width: 70px;
    height: 70px;
}

.Article > .Information{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: 16px;
}

.Article:hover{
    background-color: rgb(253, 227, 180);
}

.Articles > :last-child{
    border-bottom: .5px black solid;
}

.Article > .Information > :last-child{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Article > div > *{
    font-weight: bold;
}

.ArticleModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ArticleModal > div {
    font-weight: bold;
    font-size: 19px;
    text-rendering: optimizeLegibility;
    margin-bottom: 16px;
    width: 100%;
}


.ArticleModal > div > .ant-input-number{
    width: auto;
}

.ArticleModal > :first-child {
    font-size: 25px;
}

.ArticleModal > * > .CodArtic{
    font-weight: normal;
    font-size: 15px;
    text-rendering: optimizeLegibility;
}
.Filter{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(165,165,204,1);
    padding: 16px;
}

.Filter > .Item{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    width: 20%;
    min-width: 170px;
}

.Filter > .Item > label{
    font-weight: bold;
    font-size: large;
    color: white;
}
:root{
  /* IT&P */
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  /* Babetecno */
  /* --font-family:Roboto; */
}

body{
  font-family: var(--font-family)!important;
  margin: 0px;
}

.Modal{
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.No-Display{
  display: none!important;
}

.CheckboxText{
  margin-left: 8px;
}

fieldset > legend{
  font-size: 25px;
  font-weight: bold;
}

/* ant-btn{
  background-color: rgb(176, 173, 194);
  border-color: rgb(176, 173, 194);
  font-weight: bold!;
}

ant-btn:hover{
  background-color: rgb(255, 152, 0);
  border-color: rgb(255, 152, 0);
  transform: scale(1.5)!important;
} */

.Companies{
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    min-height: 100vh;
    background-color: rgb(250, 254, 253);
}

.Companies > .Buttons > button{
    min-width: 150px;
    height: 40px;
}

.Companies > .Right{
    display: flex;
    justify-content: flex-end;
}

.Companies > * {
    width: 100%;
    max-width: 800px;
}

.Companies .ant-collapse-content-box > :first-child{
    margin: 0px 0px;
}

.Companies .ant-collapse-content-box > div{
    margin: 8px 0px;
}

.Companies > .Container{
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border: 1px #ddd solid;
    padding: 16px;
    background-color: white;
}

.Companies > .Container > div{
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.Companies .Logo{
    padding: 16px;
    height: 86px;
}

.Companies .Logo > img{
    height: 56px;
    width: 91px;
}

.ColorExample {
    display: flex;
    width: 33px;
    border: 1px solid;
    align-self: flex-end;
    height: 33px;
}
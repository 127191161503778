.Users{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.UsersModal{
    display: flex;
    flex-direction: column;
}

.UsersModal > :last-child{
    margin-top: 16px;
}

.Users > .Buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.Users > .UsersSearch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.Users > .UsersSpin {
    padding: 16px 0px;
}

.Users > .Container{
    display: flex;
    width: 100%;
    margin-top: 32px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border: 1px #ddd solid;
}

.Users > .Container > div{
    display: flex;
    flex-direction: column;
    min-width: 50%;
    padding: 18px;
}

.Users > .Container > div > div{
   margin-bottom: 8px;
}

.Users > .Container > div > div:last-child{
    margin-bottom: 0px;
}

.Users > .Modal > .Content {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(249, 254, 255);
    margin: auto;
    padding: 32px;
}

.Users > .Modal > .Content > div{
    margin-bottom: 16px;
}

.Users > .Modal > .Content > .Options{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.Users > .Modal > .Content > .Options > button{
    width: 40%;
}

.ListUsers{
    display: flex;
    flex-direction: column;
    padding: 20px 32px;
}

.ListUsers > div > hr{
    width: 100%;
}

.ListUsers > div > div{
    display: flex;
}

.ListUsers > div > div > div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 8px;
    overflow: auto;
}

.ListUsers > div:first-child > div > div{
    justify-content: center;
}

.ListUsers > div > div > div > div, .ListUsers > div > div > div > button{
    width: 100%;
}

.Permissions {
    display: flex;
    flex-direction: column;
}

.Permissions > :first-child{
    display: flex;
    justify-content: center;
}

.Permissions > :last-child{
    display: flex;
    flex-wrap: wrap;
}

.Permissions > :last-child > label{
    width: 30%;
}

.Permissions .Title{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 8px;
}

.Permissions .See, .Permissions .Create{
    width: 100%;
    margin-bottom: 8px;
}

.Permissions .See > div:last-child, .Permissions .Create > div:last-child{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Permissions .See > div:last-child > *, .Permissions .Create > div:last-child > *{
    margin-bottom: 4px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px!important;
}

.UsersModal .ant-select{
    margin-bottom: 16px;
}
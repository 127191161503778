.Charges{
    display: flex;
    flex-direction: column;
}

.Charges > .Header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 16px;
}

.Charges > .Header > div{
    margin-right: 8px;
}

.Charges > .Header > .SearchCharges{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Charges > .Header > .SearchCharges > *{
    height: 100%;
}

.Charges > .Fields{
    display: flex;
    padding: 0px 5%;
}

.Charges > .Fields > div{
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.Charges > .Fields > div:last-child{
    display: flex;
    justify-content: flex-end;
}

.Charges > .Container{
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding: 16px 0px 0px 0px;
}

.Charges .Charge{
    display: flex;
    padding: 0px 5%;
}

.Charges .Charge > div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.Charges .Charge > div:last-child{
    align-items: flex-end;
}

.Charges .Charge > div >div{
    margin-bottom: 4px;
}

.Charges hr{
    width: 90%;
}

.Charges > .Container > hr:last-child {
    visibility: hidden;
}
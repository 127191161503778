.Documents{
    display: flex;
    padding: 20px 32px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Documents > div > hr{
    width: 100%;
}

.Documents > div > div{
    display: flex;
}

.Document {
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
    transition: 0.3s;
    border: 1px black solid;
    border-radius: 4px;
    width: 320px;
    min-height: 130px;
    margin: 16px;
}

.Document > div {
    display: flex;
    flex-direction: column;
}

.Document > :first-child {
    width: 80%;
    border-right: 1px solid black;
    padding: 5px 8px;
    align-items: center;
}

.Document > :last-child {
    width: 20%;
    padding: 8px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Document > :last-child > * {
    background-image: url('../../images/see.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.Document > div > .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    width: 100%;
    font-weight: bold;
}

.Document > div > .Body {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 5px;
}

.Document > div > .Body > .Info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}

.Document > div > .Body > :last-child {
    align-items: flex-end;
}

.Document > div > .Body > .Info > .Subject {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 22px;
}

.Document > div > .Body > .Info > .Status {
    font-weight: bold;
}

.Document > div > .Body > .Info > .Client {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Document > div > .Body > :last-child > :last-child {
    font-weight: bold;
}

.SearchModal .ant-modal-body {
    height: 80vh;
}
.ClientFile {
    display: flex;
}

.ClientFile > aside {
    display: flex;
    flex-direction: column;
    min-width: 265px;
    min-height: calc(100vh - 86px);
}

.ClientFile > aside > div {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0px 16px;
    font-size: 20px;
    font-weight: bold;
    border-right: 1px black solid;
    border-bottom: 1px black solid;
    cursor: pointer;
}

.ClientFile > aside > div:hover{
    background-color: rgb(253, 227, 180);
}

.ClientFile > aside > .Selected{
    background-color: rgb(253, 227, 180);
}

.ClientFile > aside > div > a {
    color: rgba(0, 0, 0, 0.65)
}

.ClientFile > div {
    width: 100%;
}

.ClientFile > .Page {
    padding: 32px;
}
.Login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    min-height: calc(100vh - 338px);
    background-image: url('./images/Fondo.jpg');
    background-position:center;
	background-repeat:no-repeat;
	background-size:100% 100%;
}

.Login .Error{
    color: red;
    margin-bottom: 8px;
}

.Login .Box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    border: 1px rgb(176, 173, 194) solid;
    border-radius: 4px;
    padding: 20px;
    background-color: white;
    margin-bottom: 16px;
}

.Login .Box > *{
    margin-bottom: 12px;
}
.Order{
    display: flex;
    flex-direction: column;
}

.Order label {
    font-size: 25px;
    font-weight: bold;
}

#CreateOrder {
    height: 100px;
    margin-top: 8px;
}

.Order > div{
    margin-top: 8px;
}

.Order > .OrderLines{
    display: flex;
    flex-direction: column;
    min-height: 140px;
    width: 100%;
    border: 1px rgb(192, 157, 157) solid;
    border-radius: 4px;
    margin: 16px 0px;
    background-color: rgb(252, 241, 241);
}

.Order > .OrderLines > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-radius: 2px;
}

/* .Order > .OrderLines > .OrderLine > div{
    min-width: 90px;
    height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 8px;
}

.Order > .OrderLines > .OrderLine > div.Description{
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
} */
.Order > .LineFields{
    display: flex;
    justify-content: flex-start;
    padding: 0px 8px;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
}

.Order > .LineFields > div{
    min-width: 90px;
    height: auto;
    margin-right: 8px;
}

.Order > .LineFields > div.Description{
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Order > .OrderLines > .OrderLine{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: bold;
    border-bottom: 1px black solid;
    padding: 8px;
    height: auto;
}

.Order > .OrderLines > .OrderLine > img{
    min-height: 100px;
    min-width: 100px;
    max-height: 100px;
    max-width: 100px;
    margin-right: 16px;
}

.Order > .OrderLines > .OrderLine > .Body{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.Order > .OrderLines > .OrderLine > .Body > .Information{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.Order > .OrderLines > .OrderLine > .Body > :last-child{
    display: flex;
    flex-direction: row;
}

.Order > .OrderLines > .OrderLine > .Body > :last-child > :first-child{
    margin-right: 8px;
}

.Order > .OrderLines > .OrderLine > .Body > div > .Edit{
    max-height: 55px;
    max-width: 55px;
    min-height: 55px;
    min-width: 55px;
    background-image: url('../../images/EditLineButton.png');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.Order > .OrderLines > .OrderLine > .Body > div > .Update{
    max-height: 55px;
    max-width: 55px;
    min-height: 55px;
    min-width: 55px;
    background-image: url('../../images/SaveLineButton.png');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.Order > .OrderLines > .OrderLine > .Body > div > .Edit:focus, 
.Order > .OrderLines > .OrderLine > .Body > div > .Update:focus,
.Order > .OrderLines > .OrderLine > .Body > div > .Remove:focus{
    outline: none;
}

.Order > .OrderLines > .OrderLine > .Body > div > .Remove{
    max-height: 55px;
    max-width: 55px;
    min-height: 55px;
    min-width: 55px;
    background-image: url('../../images/RemoveLineButton.png');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.Order > .OrderLines > .OrderLine:hover{
    background-color: rgb(253, 227, 180);
}

.Order > .OrderLines > .OrderLine > .Body > .Information > div{
    width: fit-content;
}

.Order > .OrderLines > .OrderLine > .Body > .Information > * > .ant-select, 
.Order > .OrderLines > .OrderLine > .Body > .Information > * > input {
    width: 100px;
}

.Order > .OrderLines > .OrderLine > .Body > .Information > .ChangeField{
    display:flex;
    flex-direction: row;
}

.Order > .OrderLines > .OrderLine:last-child{
    border-bottom: none;
}

.AddLine{
    cursor: pointer;
    outline: none;
}

.AddLine:hover{
    background-color: rgb(253, 227, 180);
}

.ModalArticles label {
    font-size: 25px;
    font-weight: bold;
}

.ModalArticles{
    padding: 0px 32px;
}

.ModalArticles > .SelectFamily{
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.ModalArticles > .SearchArticles {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 32px;
}

.ModalArticles > .SearchArticles > :first-child {
    width: 90%;
}

.ModalArticles > .SearchArticles > :last-child {
    width: 10%;
    height: 31.32px;
}

.ant-popover-inner-content > * {
    width: 100%;
    height: 100%;
}
.ConsumedArticles{
    display: flex;
    flex-direction: column;
}

.ConsumedArticles > .Header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 16px;
}

.ConsumedArticles > .Header > .Title{
    font-size: 35px;
    font-weight: bold;
}

.ConsumedArticles > .Header > div{
    margin-right: 8px;
}

.ConsumedArticles > .Header > .SearchConsumedArticles{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 32px;
}

.ConsumedArticles > .Header > .SearchConsumedArticles > *{
    height: 100%;
}

.ConsumedArticles > .Fields{
    display: flex;
    justify-content: space-between;
    padding: 0px 5%;
}

.ConsumedArticles > .Fields > div:first-child{
    width: 40%;
}

.ConsumedArticles > .Fields > div{
    font-size: 20px;
    font-weight: bold;
    width: 20%;
    text-align: center;
}

.ConsumedArticles > .Container{
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding: 16px 0px 0px 0px;
}

.ConsumedArticle{
    display: flex;
    flex-direction: row;
    padding: 0px 5%;
    align-items: center;
    height: 80px;
}
.ConsumedArticle img{
    width: 80px;
    height: 80px;
}

.ConsumedArticle > div {
    width: 20%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.ConsumedArticle > div:first-child{
    text-align: start;
}

.ConsumedArticle > .Identifier{
    display: flex;
    flex-direction: row;
    width: 40%;
    align-items: center;
}

.ConsumedArticle > .Identifier > .Information{
    display: flex;
    flex-direction: column;
    margin-left: 32px;
}

.ConsumedArticles hr{
    width: 90%;
}

.ConsumedArticles > .Container > hr:last-child {
    visibility: hidden;
}
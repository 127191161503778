.Home{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://itandp.es/wp-content/b2b/FONDO_HOME.jpg');
    background-position:center;
	background-repeat:no-repeat;
	background-size:100% 100%;
    height: calc(100vh - 86px);
}

.Home > .Welcome{
    width: 60vw;
    height: 60vh;
    background-image: url('./images/CUADRO_HOME.jpg');
    background-position:center;
    background-repeat:no-repeat;
    background-size:100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 16px;
}

.Home > .Welcome > .Title{
    font-size: 40px;
    text-align: center;
    margin-bottom: 8px;
    user-select: none;
}

.Home > .Welcome > .Info{
    font-size: 17px;
    text-align: center;
    user-select: none;
}
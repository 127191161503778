.Contracts > .Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px;
}
.Contract{
    display: flex;
    flex-direction: column;
    padding: 4px;
    border: 1px black solid;
    border-radius: 1px;
    width: 250px;
    height: 350px;
    margin: 16px;
    cursor: pointer;
}

.Contract:hover{
    transform: scale(1.02);
}

.Contract > .Head{
    font-weight: bold;
    border-bottom: 1px black solid;
}

.Contract > .Body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    height: 100%;
}

.Contract > .Body > .ContractImage{
    height: 200px;
    background-image: url('./images/contract.png');
    background-position:center;
    background-repeat:no-repeat;
    background-size:100% 100%;
}

.Contract > .Body > .ContractType, .Contract > .Body > .Signed{
    font-weight: bold;
}

.ContractModal .ant-modal-body {
    height: 80vh;
}

.ContractSign{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContractSign > *{
    width: 600px;
    align-self: center;
}

.ContractSign > label{
    margin-top: 16px;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 20px;
}

.ContractSign > canvas{
    height: 200px;
    border: 1px black solid;
}

.ContractSign > div:first-child{
    padding: 8px 0px;
    font-size: 20px;
    font-weight: bold;
    align-self: auto;
    width: 700px;
    height: auto;
    border: none;
    text-align: center;
}

.ContractSign > div:last-child{
    padding: 32px 0px;
    font-size: 20px;
    font-weight: bold;
    align-self: auto;
    width: 700px;
    height: auto;
    border: none;
    text-align: center;
}

.ant-modal-body .ant-spin-spinning{
    width: 100%;
    height: 100%;
    padding-top: 30%;
}
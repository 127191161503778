footer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 0px 150px;
    background-color: #525192;
}

footer > .Social{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

footer .Item {
    display: flex;
    padding: 32px;
    min-width: 315px;
}

footer .Description {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: bold;
    font-size: larger;
}

footer .Icon {
    display: flex;
    justify-content: flex-start;
    padding: 8px 20px;
}

footer .Icon > img{
    width: 32px;
    height: 32px;
}

footer .Description .Title {
    margin: 0px;
    text-align: center;
    color: white;
}

footer .Description .LinkContainer {
    text-align: center;
}

footer .Description .LinkContainer .Link {
    color: rgba(165,165,204,1);
}

footer .MoreInfo {
    font-weight: 500;
    font-size: initial;
    text-align: center;
    color: white;
}
.Clients{
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.Clients > .Buttons{
    margin-bottom: 16px;
}

.Clients > .Container{
    display: flex;
    width: 100%;
    margin-top: 32px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border: 1px #ddd solid;
}

.Clients > .Container > div{
    display: flex;
    flex-direction: column;
    min-width: 50%;
    padding: 18px;
}

.Clients > .Container > div > div{
   margin-bottom: 8px;
}

.Clients > .Container > div > div:last-child{
    margin-bottom: 0px;
}

.Clients > .Modal > .Content {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(249, 254, 255);
    margin: auto;
}
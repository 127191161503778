.Search{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 82px);
}

.Search > .Modal{
    display: flex;
    justify-content: center;
}

.CreateDocumentButton{
    position: fixed;
    width: 80px!important;
    height: 80px!important;
    right: 70px;
    bottom: 70px;
    background-image: url('../../images/aviso.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    cursor: pointer;
}